const axios = require('axios');

export async function participate(data) {
    try {
        // const response = await axios.post('http://10.168.1.117:19273/participate', data, {
        const response = await axios.post('https://zhuajiu18273121.meihuabo.com/api/participate', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        // console.error(error)
        // console.error('Error:', error.response ? error.response.data : error.message);
        return error.response.data
    }
}


export async function participateList() {
    try {
        // const response = await axios.get('http://10.168.1.117:19273/participants', {
        const response = await axios.get('https://zhuajiu18273121.meihuabo.com/api/participants', {
            name: 'Alice'
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return {}
    }
}
